import "core-js/modules/esnext.iterator.map.js";
import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import MainDescription from "@/components/MainDescription.vue";
import AppSelect from "@/components/AppSelect.vue";
import KeywordsDynamicTable from "@/components/Rating/KeywordsDynamicTable";
import Tags from "@/components/DataTable/Filter/Tags.vue";
import Tooltip from "@/components/Tooltip.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
export default {
  name: "Home",
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Аналитика и статистика продаж маркетплейса Wildberries, анализ лучших категорий и ниш для продаж на Вайлдберриз, анализ конкурентов" : "Аналитика и статистика продаж маркетплейса OZON, анализ лучших категорий и ниш для продаж на ОЗОН, анализ конкурентов",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детальной аналитики товаров Вайлдберриз! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов Wildberries, поисковые запросы, кабинет продавца по API" : "SalesFinder - сервис детальной аналитики товаров OZON! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов ОЗОН, поисковые запросы, кабинет продавца по API"
      }]
    };
  },
  components: {
    MainPage,
    MainTitle,
    MainDescription,
    AppSelect,
    KeywordsDynamicTable,
    Tags,
    Tooltip,
    DataTableActions
  },
  data() {
    const initialList = {
      fromMonth: "",
      toMonth: "",
      trend: {
        id: "growth",
        title: "Рост"
      },
      percent: "",
      showLess: "",
      keyword: []
    };
    return {
      initialList,
      list: {
        ...initialList
      },
      trendsOptions: [{
        id: "growth",
        title: "Рост"
      }, {
        id: "fall",
        title: "Падение"
      }],
      limitKwd: {},
      months: [],
      trendsTop100: true,
      limitError: false
    };
  },
  async created() {
    await this.loadKwdLimits();
  },
  watch: {
    "$route.params.mp": function () {
      this.list = {
        ...this.initialList
      };
    },
    months(newVal, oldVal) {
      if (!oldVal[0]) {
        const monthsCount = newVal.length;
        const defaultMonth1 = {
          id: newVal[monthsCount - 2],
          title: newVal[monthsCount - 2]
        };
        const defaultMonth2 = {
          id: newVal[monthsCount - 1],
          title: newVal[monthsCount - 1]
        };
        this.list.fromMonth = {
          ...defaultMonth1
        };
        this.list.toMonth = {
          ...defaultMonth2
        };
      }
    }
  },
  methods: {
    async loadKwdLimits() {
      this.limitKwd = await this.$store.dispatch("keyword/getKeywordLimit", {
        fake: 1
      });
    },
    updateMonths(months) {
      this.months = months;
    },
    getTableId() {
      return this._uid;
    },
    trendsSearch() {
      if (!this.authorized) {
        this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
          message: "Данный функционал доступен только для зарегистрированных пользователей"
        });
        return;
      }
      this.trendsTop100 = false;
      this.$refs.keywordsDynamicTable.refresh();
    },
    limitReached(message) {
      this.limitError = true;
      this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedTemplate"), {
        alert: "Превышен лимит на вашем тарифном плане",
        title: message
      });
    }
  },
  computed: {
    authorized() {
      return this.$store.state.user.user;
    },
    trendsSettings() {
      return {
        fromMonth: this.list.fromMonth.id,
        toMonth: this.list.toMonth.id,
        trend: this.list.trend.id,
        percent: +this.list.percent,
        showLess: +this.list.showLess,
        keyword: this.list.keyword
      };
    },
    valid() {
      const monthsAreCorrect = this.months.findIndex(month => {
        var _this$list$fromMonth;
        return month === ((_this$list$fromMonth = this.list.fromMonth) === null || _this$list$fromMonth === void 0 ? void 0 : _this$list$fromMonth.id);
      }) <= this.months.findIndex(month => {
        var _this$list$toMonth;
        return month === ((_this$list$toMonth = this.list.toMonth) === null || _this$list$toMonth === void 0 ? void 0 : _this$list$toMonth.id);
      });
      const trendIsCorrect = this.list.trend.id === "fall" ? +this.list.percent <= 100 : true;
      const percentIsCorrect = /^\d+$/.test(this.list.percent);
      return monthsAreCorrect && percentIsCorrect && trendIsCorrect;
    },
    monthsOptions() {
      const reversedMonths = [...this.months].reverse();
      return reversedMonths.map(month => {
        return {
          id: month,
          title: month
        };
      });
    },
    reportSettings() {
      return {
        mp: this.$route.params.mp
      };
    }
  }
};