var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "small-chart",
    on: {
      "click": function ($event) {
        return _vm.$emit('loadChart', _vm.dataset);
      }
    }
  }, [_c('small-chart', {
    attrs: {
      "dataset": _vm.dataset,
      "color": _vm.$store.state.theme === 'dark' ? '#6035ac' : '#6D8A9F'
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };