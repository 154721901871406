import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import DataTable from "@/components/DataTable/DataTable";
import KeywordsDynamicChart from "@/components/Rating/KeywordsDynamicChart.vue";
import { toExportFormat } from "@/utils/export";
import LineChart from "@/components/charts/LineChart.vue";
import MainTitle from "@/components/MainTitle.vue";
import { today } from "@/utils/dates";
import _ from "lodash";
export default {
  components: {
    DataTable,
    KeywordsDynamicChart,
    LineChart,
    MainTitle
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    trendsSettings: {
      type: Object || null,
      required: false,
      default: null
    },
    trendsTop100: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateMonths", "limitReached"],
  data() {
    return {
      oldFilters: {},
      months: [],
      chartRefresh: 0,
      chartDataset: [],
      showLargeChart: false,
      largeChartKeyword: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        options: {
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true
              }
            }
          }
        },
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              display: true
            },
            gridLines: {
              display: true
            },
            scaleLabel: "test"
          }],
          xAxes: [{
            ticks: {
              display: true
            },
            gridLines: {
              display: true
            },
            scaleLabel: "test"
          }]
        },
        tooltips: {
          intersect: false,
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.6)",
          caretSize: 0,
          backgroundColor: "#ffffff",
          xPadding: 16,
          yPadding: 12,
          displayColors: false,
          titleFontFamily: "Ubuntu",
          titleFontSize: 14,
          titleFontColor: "rgba(0, 0, 0, 0.6)",
          titleFontStyle: "normal",
          bodyFontFamily: "Ubuntu",
          bodyFontSize: 14,
          bodyFontColor: "rgba(0, 0, 0, 0.9)",
          bodyFontStyle: "bold"
        }
      }
    };
  },
  watch: {
    chartData() {
      this.chartRefresh++;
    }
  },
  methods: {
    hideLargeChart() {
      this.showLargeChart = false;
    },
    loadLargerChart(dataset, keyword) {
      this.showLargeChart = true;
      this.largeChartKeyword = keyword;
      this.chartDataset = dataset.map(item => item === "-" ? 0 : item);
      setTimeout(() => {
        document.getElementsByClassName("chart-container")[0].scrollIntoView({
          behavior: "smooth",
          // Smooth scroll animation
          block: "start",
          // Align the element to the top of the viewport
          inline: "nearest" // For inline scrolling
        });
      }, 100);
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      if (query.field) {
        return this.$store.dispatch("keyword/getKeywordDynamic", {
          ...query,
          ...this.reportSettings
        });
      } else {
        let filters = null;
        const keys = Object.keys(query);
        if (keys.some(key => key.startsWith("f."))) {
          filters = keys.filter(key => key.startsWith("f.")).reduce((acc, key) => {
            acc[key] = query[key];
            return acc;
          }, {});
        }
        const body = {
          ...query,
          ...this.reportSettings
        };
        if (filters) {
          body.filters = filters;
        }
        if (this.trendsSettings) {
          body.trends = this.trendsSettings;
        }
        if (this.trendsTop100) {
          body.trendsTop100 = true;
        }
        if (!_.isEqual(filters, this.oldFilters)) {
          this.$emit("updateFilters", filters);
        }
        this.oldFilters = {
          ...filters
        };
        try {
          const result = await this.$store.dispatch("keyword/getKeywordDynamic", body);
          if (result.items[0]) {
            const months = Object.keys(result.items[0].date_shows);
            this.months = months;
            result.items.forEach(item => {
              months.forEach(month => {
                item[month] = item.date_shows[month];
              });
            });
          }
          this.$emit("updateMonths", this.months);
          if (query.action === "export") {
            return toExportFormat(result, this.columns.filter(column => column.name !== "date_shows"));
          } else {
            return result;
          }
        } catch (e) {
          const errorMessage = e.message;
          if (typeof errorMessage === "string" && errorMessage !== "no-limit-error") {
            this.$emit("limitReached", errorMessage);
          }
        }
      }
    },
    refresh() {
      this.$refs.table.refresh();
    }
  },
  computed: {
    exportFileName() {
      return `SalesFinder Динамика поисковых запросов ${today("DD.MM.YYYY")}`;
    },
    chartData() {
      return {
        labels: this.months,
        datasets: [{
          borderColor: "#E0A1B5",
          pointBackgroundColor: "#E0A1B5",
          pointBorderColor: "#FFFFFF",
          backgroundColor: "gradient",
          data: this.chartDataset
        }]
      };
    },
    columns() {
      return [{
        title: "Поисковый запрос",
        name: "keyword",
        width: 300,
        show: true,
        filter: "tags",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "KeywordInfo",
              params: {
                k_id: item.k_id
              },
              query: {
                // date: this.reportSettings.date,
                // date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keyword,
            target: "_blank",
            slotRight: `
                            <a href="${this.$route.params.mp === "wb" ? "https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=" : "https://www.ozon.ru/search/?from_global=true&text="}${item.keyword}" target="_blank">
                                <svg class="external-link-icon" viewBox="0 0 24 24" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"/></g></svg>
                            </a>`,
            slotRightHover: true
          };
        }
      }, {
        title: "Динамика частотности",
        show: true,
        name: "date_shows",
        width: 200,
        type: "slot"
      }, ...this.months.map(month => {
        return {
          title: month,
          show: true,
          name: month,
          width: 120,
          type: "number",
          noSettings: true,
          filter: "numberRange"
        };
      })];
    }
  }
};