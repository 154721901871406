var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLargeChart,
      expression: "showLargeChart"
    }],
    staticClass: "chart-container"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-outline",
          on: {
            "click": _vm.hideLargeChart
          }
        }, [_vm._v(" Скрыть график ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" Динамика частотности по запросу "), _c('span', {
    staticClass: "blue-text"
  }, [_vm._v(_vm._s(_vm.largeChartKeyword) + " ")])]), _c('line-chart', {
    key: _vm.chartRefresh,
    staticClass: "mt32",
    attrs: {
      "chartData": _vm.chartData,
      "options": _vm.chartOptions,
      "height": 350
    }
  })], 1), _c('data-table', _vm._b({
    ref: "table",
    staticClass: "mt32",
    attrs: {
      "table-id": "keywords_dynamic",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "k_id",
      "selectable": false,
      "stickyColumns": 2,
      "qSkip_FilterButton": true,
      "qSkip_ViewButton": true,
      "fileName": _vm.exportFileName
    },
    scopedSlots: _vm._u([{
      key: "date_shows",
      fn: function ({
        item
      }) {
        return [_c('keywords-dynamic-chart', {
          attrs: {
            "item": item
          },
          on: {
            "loadChart": dataset => _vm.loadLargerChart(dataset, item.keyword)
          }
        })];
      }
    }])
  }, 'data-table', _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };