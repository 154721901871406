var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main-page', {
    key: this.$route.params.mp
  }, [_c('main-title', [_vm._v(" Поиск трендовых запросов " + _vm._s(_vm.$route.params.mp === "wb" ? "Wildberries" : "Ozon") + " ")]), _c('main-description', {
    staticClass: "mt8"
  }, [_vm._v(" В этом разделе вы можете найти и проанализировать зарождающиеся тренды, а также сезонные запросы покупателей ")]), _vm.authorized ? _c('div', {
    staticClass: "form mt32"
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Период с")]), _c('app-select', {
    attrs: {
      "items": _vm.monthsOptions,
      "fullwidth": ""
    },
    model: {
      value: _vm.list.fromMonth,
      callback: function ($$v) {
        _vm.$set(_vm.list, "fromMonth", $$v);
      },
      expression: "list.fromMonth"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Период по")]), _c('app-select', {
    attrs: {
      "items": _vm.monthsOptions,
      "fullwidth": ""
    },
    model: {
      value: _vm.list.toMonth,
      callback: function ($$v) {
        _vm.$set(_vm.list, "toMonth", $$v);
      },
      expression: "list.toMonth"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Исходная частотность (опционально) "), _c('Tooltip', {
    attrs: {
      "position": "top left"
    }
  }, [_vm._v(" Исходная частотность запроса в начальный месяц из сравнения. Используйте данную опцию для исключения трендов с низкой базой. ")])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.list.showLess,
      expression: "list.showLess"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Исходная частотность",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.list.showLess
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.list, "showLess", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Тренд")]), _c('app-select', {
    attrs: {
      "items": _vm.trendsOptions,
      "fullwidth": ""
    },
    model: {
      value: _vm.list.trend,
      callback: function ($$v) {
        _vm.$set(_vm.list, "trend", $$v);
      },
      expression: "list.trend"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Разница в % (от)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.list.percent,
      expression: "list.percent"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Разница в % (от)",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.list.percent
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.list, "percent", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Запросы содержат слова (опционально) "), _c('Tooltip', {
    attrs: {
      "position": "top left"
    }
  }, [_vm._v(" Для поиска трендов внутри конкретной ниши вы можете указать слово или несколько слов, которые должны содержаться в поисковых запросах. ")])], 1), _c('Tags', {
    attrs: {
      "placeholder": "Например, \"платье\""
    },
    model: {
      value: _vm.list.keyword,
      callback: function ($$v) {
        _vm.$set(_vm.list, "keyword", $$v);
      },
      expression: "list.keyword"
    }
  })], 1)]) : _vm._e(), _c('button', {
    staticClass: "btn btn-blue mt32",
    staticStyle: {
      "justify-self": "center"
    },
    attrs: {
      "disabled": !_vm.valid && _vm.authorized
    },
    on: {
      "click": _vm.trendsSearch
    }
  }, [_vm._v(" Найти тренды ")]), !_vm.authorized ? _c('img', {
    staticClass: "mt32",
    attrs: {
      "src": this.$store.state.theme === 'light' ? require('@/assets/images/keywordDynamic/keywordDynamicLight.png') : require('@/assets/images/keywordDynamic/keywordDynamicDark.png')
    }
  }) : _vm._e(), _vm.authorized && _vm.trendsTop100 ? _c('main-title', {
    staticClass: "mt32"
  }, [_vm._v(" ТОП-100 поисковых запросов с максимальным ростом за последний месяц ")]) : _vm._e(), _vm.authorized && !_vm.trendsTop100 ? _c('main-title', {
    staticClass: "mt32"
  }, [_vm._v(" Поисковые запросы по заданным параметрам ")]) : _vm._e(), _vm.reportSettings && !_vm.limitError && _vm.authorized ? _c('div', {
    staticClass: "mt32 actions"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1) : _vm._e(), _vm.reportSettings && !_vm.limitError && _vm.authorized ? _c('div', [_c('keywords-dynamic-table', {
    ref: "keywordsDynamicTable",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "trends-settings": _vm.trendsSettings,
      "trendsTop100": _vm.trendsTop100
    },
    on: {
      "updateMonths": _vm.updateMonths,
      "limitReached": _vm.limitReached
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };