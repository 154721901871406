import "core-js/modules/esnext.iterator.map.js";
import SmallChart from "@/components/charts/SmallChart";
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    SmallChart
  },
  computed: {
    dataset() {
      return Object.values(this.item.date_shows).map(item => item === "-" ? 0 : item);
    }
  }
};